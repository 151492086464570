var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"content-box"},[_c('el-row',{staticStyle:{"padding-left":"10px","background":"#fff"}},[_c('el-col',{staticClass:"types",attrs:{"span":5}},_vm._l((_vm.typeArray),function(type,k){return _c('div',{key:k,style:(k === 0 ? 'margin-top: 0' : 'margin-top: 10px')},[_c('div',{staticClass:"type-title"},[_vm._v(_vm._s(type.name))]),_vm._l((type.child),function(childType,index){return _c('div',{key:index,staticClass:"type-child",staticStyle:{"display":"inline-block"}},[_c('router-link',{attrs:{"to":'goods?type=' + childType.id + '&type_text=' + childType.name,"target":"_blank"}},[_vm._v(_vm._s(childType.name))])],1)})],2)}),0),_c('el-col',{attrs:{"span":19}},[_c('div',{staticStyle:{"max-width":"1100px"}},[_c('el-carousel',{attrs:{"height":"390px"}},_vm._l((_vm.carousel),function(item){return _c('el-carousel-item',{key:item.carousel_id,attrs:{"interval":2000}},[(item.type === 1 || item.type >= 3)?_c('img',{staticStyle:{"height":"390px","width":"100%"},attrs:{"src":item.image,"alt":item.title},on:{"click":function($event){return _vm.redirectDiffPage(item)}}}):(item.type === 2)?_c('video-player',{ref:"videoPlayer",refInFor:true,staticClass:"video-player vjs-custom-skin",attrs:{"id":"videoPlayer","playsinline":true,"options":_vm.videoOptions[item.id]}}):_vm._e()],1)}),1)],1),_c('div',{staticStyle:{"margin-top":"8px","max-width":"1100px","height":"215px"}},[_c('div',{staticClass:"quick-f"},[_c('router-link',{attrs:{"to":{
                path: '/shop',
                query: { SID: 30 },
              },"target":"_blank"}},[_c('el-image',{attrs:{"src":require('@/assets/imgs/f1.png')}})],1)],1),_c('div',{staticClass:"quick-f"},[_c('router-link',{attrs:{"to":{
                path: '/shop',
                query: { SID: 367 },
              },"target":"_blank"}},[_c('el-image',{attrs:{"src":require('@/assets/imgs/f2.png')}})],1)],1),_c('div',{staticClass:"quick-f"},[_c('router-link',{attrs:{"to":{
                path: '/shop',
                query: { SID: 112 },
              },"target":"_blank"}},[_c('el-image',{attrs:{"src":require('@/assets/imgs/f3.png')}})],1)],1),_c('div',{staticClass:"quick-f"},[_c('router-link',{attrs:{"to":{
                path: '/shop',
                query: { SID: 1177 },
              },"target":"_blank"}},[_c('el-image',{attrs:{"src":require('@/assets/imgs/f4.png')}})],1)],1)])])],1),_c('div',{staticStyle:{"height":"20px"}},[_vm._v(" ")]),_c('div',{staticClass:"main-box"},[_c('div',{staticClass:"main"},[_c('div',{staticStyle:{"background":"#fff"}},[_c('el-row',[_c('el-col',{staticClass:"title",attrs:{"span":2}},[_vm._v("品牌商家")]),_c('el-col',{staticClass:"desc",attrs:{"span":20}},[_vm._v("绒博汇最全商家，最新毛绒玩具批发火爆热销中")]),_c('el-col',{staticClass:"more",attrs:{"span":2}},[_c('router-link',{attrs:{"to":"goods","target":"_blank"}},[_vm._v("更多")])],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":2}},[_c('ul',{staticClass:"shopList"},_vm._l((_vm.shops),function(item,key){return _c('li',{key:key,class:item.sp_id == _vm.hoverShopId ? 'hoverLi' : '',on:{"mouseenter":function($event){return _vm.switchShopGoods(item.sp_id)}}},[_c('router-link',{attrs:{"to":{
                      path: '/shop',
                      query: { SID: item.sp_id },
                    },"target":"_blank"}},[_c('a',[_vm._v(_vm._s(item.title))])])],1)}),0)]),_c('el-col',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"padding-right":"10px"},attrs:{"span":22,"element-loading-text":"加载中"}},[_c('el-row',[_c('div',{staticStyle:{"width":"58%","display":"inline-block","height":"470px","padding-left":"2%"}},[_c('el-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.shopGoods[0] ? _vm.shopGoods[0].image : ''}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])])],1),_c('div',{staticStyle:{"width":"39%","display":"inline-block","height":"470px","padding-left":"1%"}},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{staticClass:"twoEach",attrs:{"span":12}},[_c('router-link',{attrs:{"to":{
                          path: '/detail',
                          query: { id: _vm.shopGoods[1] ? _vm.shopGoods[1].id : 0 },
                        },"target":"_blank"}},[_c('el-image',{attrs:{"src":_vm.shopGoods[1] ? _vm.shopGoods[1].Picture : ''}}),_c('p',[_vm._v(_vm._s(_vm.shopGoods[1] ? _vm.shopGoods[1].name : ""))])],1)],1),_c('el-col',{staticClass:"twoEach",attrs:{"span":12}},[_c('router-link',{attrs:{"to":{
                          path: '/detail',
                          query: { id: _vm.shopGoods[2] ? _vm.shopGoods[2].id : 0 },
                        },"target":"_blank"}},[_c('el-image',{attrs:{"src":_vm.shopGoods[2] ? _vm.shopGoods[2].Picture : ''}}),_c('p',[_vm._v(_vm._s(_vm.shopGoods[2] ? _vm.shopGoods[2].name : ""))])],1)],1)],1),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{staticClass:"twoEach",attrs:{"span":12}},[_c('router-link',{attrs:{"to":{
                          path: '/detail',
                          query: { id: _vm.shopGoods[3] ? _vm.shopGoods[3].id : 0 },
                        },"target":"_blank"}},[_c('el-image',{attrs:{"src":_vm.shopGoods[3] ? _vm.shopGoods[3].Picture : ''}}),_c('p',[_vm._v(_vm._s(_vm.shopGoods[3] ? _vm.shopGoods[3].name : ""))])],1)],1),_c('el-col',{staticClass:"twoEach",attrs:{"span":12}},[_c('router-link',{attrs:{"to":{
                          path: '/detail',
                          query: { id: _vm.shopGoods[4] ? _vm.shopGoods[4].id : 0 },
                        },"target":"_blank"}},[_c('el-image',{attrs:{"src":_vm.shopGoods[4] ? _vm.shopGoods[4].Picture : ''}}),_c('p',[_vm._v(_vm._s(_vm.shopGoods[4] ? _vm.shopGoods[4].name : ""))])],1)],1)],1)],1)]),_c('el-row',[_c('ul',{staticClass:"fiveList"},_vm._l((_vm.shopGoods.slice(5, 10)),function(item,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":{
                        path: '/detail',
                        query: { id: item ? item.id : 0 },
                      },"target":"_blank"}},[_c('el-image',{attrs:{"src":item ? item.Picture : ''}}),_c('p',[_vm._v(_vm._s(item ? item.name : ""))])],1)],1)}),0)])],1)],1)],1),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('el-row',[_c('el-col',{staticClass:"title",attrs:{"span":2}},[_vm._v("热门短视频")]),_c('el-col',{staticClass:"desc",attrs:{"span":20}},[_vm._v("绒博汇热播毛绒玩具短视频 最火爆的毛绒玩具批发短视频专区")]),_c('el-col',{staticClass:"more",attrs:{"span":2}},[_c('router-link',{attrs:{"to":"goods","target":"_blank"}},[_vm._v("更多")])],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":17}},[_c('div',[_c('video-player',{ref:"videoPlayer",staticClass:"video-player vjs-custom-skin middle-video",attrs:{"id":"videoPlayer","playsinline":true,"options":_vm.videoGoodOption}})],1)]),_c('el-col',{attrs:{"span":7}},[_c('div',[_c('el-image',{staticStyle:{"height":"362.5px"},attrs:{"src":_vm.playedVideoPoster}}),_c('div',{staticClass:"rightVideoCover"},[_c('router-link',{attrs:{"to":{
                      path: '/shop',
                      query: { SID: _vm.playedItem.SID },
                    },"target":"_blank"}},[_c('div',{staticClass:"tabTitle"},[_vm._v("点击进入")])]),_c('div',{staticClass:"tabShopName"},[_vm._v(_vm._s(_vm.playedItem.SName))]),_c('p',{staticClass:"tabShopAddress"},[_vm._v(" "+_vm._s(_vm.playedItem.Addr)+" ")])],1)],1)])],1),_c('el-row',{staticStyle:{"margin-top":"10px"}},[(_vm.videoGoods[1])?_c('ul',{staticClass:"fiveVideo"},_vm._l((_vm.videoGoods.slice(1, 6)),function(item,index){return _c('li',{key:index},[_c('el-row',{staticClass:"videoInfo"},[_c('el-col',{attrs:{"span":24}},[_c('img',{staticClass:"videoCover",attrs:{"src":item ? item.cover : ''}}),_c('img',{staticClass:"playButton",attrs:{"src":_vm.playedVideoId === item.ID
                          ? require('@/assets/imgs/stop.png')
                          : require('@/assets/imgs/play.png')},on:{"click":function($event){return _vm.playVideo(item)}}})]),_c('router-link',{attrs:{"to":{
                      path: '/detail',
                      query: { id: _vm.videoGoods[index + 1].PID },
                    },"target":"_blank"}},[_c('el-col',{staticClass:"videoName",attrs:{"span":24}},[_vm._v(_vm._s(_vm.videoGoods[index + 1].PName))]),_c('el-col',{staticClass:"videoTime",attrs:{"span":24}},[_vm._v("播放"+_vm._s(_vm.videoGoods[index + 1].PHotNum)+"次")])],1)],1)],1)}),0):_vm._e()])],1),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('el-row',[_c('el-col',{staticClass:"title",attrs:{"span":2}},[_vm._v("精选单品")]),_c('el-col',{staticClass:"desc",attrs:{"span":20}},[_vm._v("绒博汇精选毛绒玩具公仔 儿童玩具 小孩玩具 创意抱枕 等身抱枕 沙发靠垫 汽车坐垫 沙发坐垫")]),_c('el-col',{staticClass:"more",attrs:{"span":2}},[_c('router-link',{attrs:{"to":"goods","target":"_blank"}},[_vm._v("更多")])],1)],1),_c('el-row',[(_vm.allRecGoods.length > 0)?_c('GoodsList',{staticStyle:{"position":"relative","left":"-10px","width":"1240px"},attrs:{"list":_vm.allRecGoods}}):_vm._e()],1)],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }